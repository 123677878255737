<template>
  <AppLayout>
    <template v-slot:appContent>
      <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
        <h4 class="">Roles/Edit</h4>
        <div>
          <router-link :to="{...previousRoute}">
            <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
          </router-link>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="col-12">
            <div class="users-list-filter px-1">
              <form>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <div class="">
                        <label for="roleName">Role Name *</label>
                        <input type="text" class="form-control" id="roleName" placeholder="Enter Role Name"
                               v-model="name">
                      </div>
                      <div v-if="errors.name !== undefined && errors.name.length > 0">
                        <p v-for="(error,index) in  errors.name" :key="index" class="text-danger mb-1">
                          {{ error }}
                        </p>
                      </div>
                    </div>

                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <div class="controls">
                        <label>Role Type *</label>
                        <VueMultiselect v-model="selectedRoleType" class="multiselect-blue" :options="roleTypes"
                                        :close-on-select="true" :allow-empty="false" placeholder="Select Role Type"
                                        label="name" track-by="value" :show-labels="false"/>
                      </div>
                      <div v-if="errors.type !== undefined && errors.type.length > 0">
                        <p v-for="(error,index) in  errors.type" :key="index" class="text-danger mb-1">
                          {{ error }}
                        </p>

                      </div>

                    </div>

                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Description</label>
                      <textarea class="form-control"
                                placeholder="Enter Description for why the role will be use for."
                                v-model="description">
                      </textarea>
                    </div>
                  </div>
                </div>

              </form>
            </div>
            <div class="card">
              <div class="card-body">

                <div class="mx-auto col-md-6 d-flex justify-content-center">
                  <div>
                    <h4 class="mb-0 ">Assign Permissions *</h4>
                    <div class="custom-control custom-checkbox mb-2 mt-1">
                      <input @click="allPermissionSelect()" v-model="selectAll" type="checkbox"
                             class="custom-control-input" id="allPermissionSelect" value="1">
                      <label for="allPermissionSelect" class="custom-control-label">Select / Unselect all
                        Permissions</label>
                    </div>
                  </div>
                </div>
                <!-- permissionList -->
                <div v-for="(permission,key) in permissionList" :key="key">
                  <h5 class="pt-1">{{ permission.name }}</h5>
                  <div class="row">
                    <div v-for="(singlePermission,key) in permission.permissions" :key="key"
                         class="col-lg-3 col-md-3 col-sm-12 col-xs-12" style="margin-top:10px;">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" checked @change="updateCheckAll()" v-model="selectedPermissions"
                               class="custom-control-input" :id="singlePermission.id" :value="singlePermission.id">
                        <label :for=" singlePermission.id" class="custom-control-label">{{
                            singlePermission.name
                          }}</label>
                      </div>

                    </div>

                  </div>
                  <hr>
                </div>

                <div class="row">
                  <div class="col-12 ">
                    <div class="d-flex justify-content-end py-1">
                      <button @click="updateSingleRole" type="button" class="btn btn-primary update-todo px-4">Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
// mixins
import Authorization from "@/components/backEnd/mixins/Authorization";
import Loader from "@/components/backEnd/mixins/Loader";
import VueMultiselect from 'vue-multiselect';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
// core packages
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "RoleEdit",
  components: {
    AppLayout,
    VueMultiselect,
  },
  mixins: [ShowToastMessage, Loader, Authorization],
  data() {
    return {
      getRoleParams: {
        with_relation: ['permissions.permissionGroup', 'permissions'],
      },
      getPermissionsParams: {
        with_relation: ['permissionGroup'],
      },
      description: '',
      name: '',
      selectedRoleType: '',
      selectAll: false,
      selectedPermissions: [],
      errors: {}

    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      roleTypes: 'roleTypes',
      permissions: 'appPermissions/permissions',
      role: 'appRoles/role',
      roles: 'appRoles/roles',

    }),
    redirectRouteName() {
      return this.can('role-view') ? 'appRoleView' : (this.can('role-view-any') ? 'appRoleViewAny' : 'appRoleCreate');
    },
    permissionList() {
      let permissionList = [];
      this.permissions.map((item) => {
        if ((item?.id ?? false) && (item?.permission_group?.id ?? false)) {

          let permission = {
            name: item.name,
            id: item.id,
          };
          let permissionGroup = {
            id: item.permission_group.id,
            name: item.permission_group.name,
          };
          let index = permissionList.findIndex(
              (newPermissionItem) => newPermissionItem.id === permissionGroup.id
          );
          if (index === -1) {
            permissionList.push({
              ...permissionGroup,
              permissions: [permission],
            });
          } else {
            permissionList[index].permissions = [...permissionList[index].permissions, permission];
          }
        }

      });

      return permissionList;

    },

  },
  watch: {
    role(currentRole) {
      this.name = currentRole.name;
      this.description = currentRole.description;
      this.selectedRoleType = this.roleTypes.find((role) => role.name === currentRole.type);
      let allPermissions = [];
      currentRole.permissions.map((role) => {
        role.permissions.map((singlePermission) => {
          if (singlePermission.is_assign === true) {
            allPermissions.push(singlePermission.id)
          }

        })
      });
      this.selectedPermissions = allPermissions;
      if (this.permissions.length === allPermissions.length) {
        this.selectAll = true;
      }

    }
  },
  methods: {
    ...mapActions({
      getPermissions: 'appPermissions/getPermissions',
      putRole: 'appRoles/putRole',
      getRole: 'appRoles/getRole',
      getRoles: 'appRoles/getRoles',
    }),

    async getRoleList() {
      await this.getRoles();
    },

    async getSingleRole() {
      let paramObj = {
        id: this.$route.params.id,
        params: this.getRoleParams,
      };
      await this.getRole(paramObj).then(async (response) => {
        if (response.status === 404) {
          this.commitNotFoundRouteStatus(true);
          return;
        }
        if (response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },
    async getPermissionList() {
      await this.getPermissions(this.getPermissionsParams).then(async (response) => {
        if (response.status !== 200) {
          this.showToastMessage(response);
        }
      });

    },

    allPermissionSelect() {

      if (!this.selectAll) {
        this.selectedPermissions = [];

        this.permissions.map((item) => this.selectedPermissions.push(item.id));
        this.selectAll = true;
      } else {
        this.selectedPermissions = [];
        this.selectAll = false;
      }
    },
    updateCheckAll: function () {
      this.selectAll = this.permissions.length === this.selectedPermissions.length;
    },
    async updateSingleRole() {
      let dataObj = {
        id: this.$route.params.id,
        data: {
          type: this.selectedRoleType?.value ?? '',
          name: this.name,
          description: this.description,
          permission_id: this.selectedPermissions,
        },
      };

      await this.loader(true);
      await this.putRole(dataObj).then(async (response) => {
        await this.loader(false);
        if (response.status === 200 || response.status === 201) {
          const toastObj = {
            message: 'Role Updated successfully.',
            type: 'success'
          };

          this.showToastMessage(toastObj);

          await this.$router.push({name: this.redirectRouteName});
          this.errors = {};
          return;
        }
        window.scrollTo(0, 0);
        this.errors = response.errors;

        if (response.message) {
          this.showToastMessage(response);
        }
      });
    }
  },
  async mounted() {
    await this.loader(true);
    await this.getRoleList();
    await this.getPermissionList();
    await this.getSingleRole();
    await this.loader(false);

  },

}
</script>

<style scoped>
p {
  margin-bottom: 0;
}

h5 {
  padding-bottom: 0;
}
</style>
